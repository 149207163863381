import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const ComponentStyles = styled.div`

    position: relative;
    z-index: 20;

    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 1.5em;
        line-height: 1;
        letter-spacing: 0.01em;
        color: ${colors.primary};
        margin: 0;
    }

    hr.divider {
        background: ${colors.solidFreeform};
        margin: 24px 0 26px;
        height: 3px;
        width:36px;
    }

    label {
        textarea, select, input[type="email"], input[type="text"] {
            margin: 0 0 12px;
            padding: 8px 0;
        }
    }
    
    div.marketing-opt{
        margin-bottom: 12px;
    }

`;