import React from 'react';
import DownloadsTemplate from '../templates/Downloads';
import { PageContextProvider } from '../context/PageContext';

const Downloads = ({ pageContext }) => {
  return (
    <PageContextProvider value={pageContext}>
      <DownloadsTemplate />
    </PageContextProvider>
  );
};

export default Downloads;
