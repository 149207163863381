import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const TitleWrapper = styled.div`

    &.desktop {
        display: none;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            display: flex;
        }
    }

    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 1.25em;
        line-height: 1;
        color: ${colors.primary};
        margin-bottom: 12px;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            margin-bottom: 0;
        }
    }
`;

export const ProductsWrap = styled.div`
    
    &.desktop{
        display: none;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            display: flex;
        }
    }
    
    img {
        height: 22px;
        margin-right: 12px;
    }
`;

export const MobileWrap = styled.div`
    
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        display: none;
    }

`;


export const Divider = styled.div`
    @media (min-width: ${mediaQueriesSizes.sm}px) {
        grid-column: span 4;
    }
    height: 1px;
    background: ${colors.UILightTertiary};
`;
export const ButtonWrap = styled.div`
    text-align: right;
    &.mobile {
        @media (min-width: ${mediaQueriesSizes.sm}px) {
            display: none;
        }
    }
    &.desktop {
        display: none;
        @media (min-width: ${mediaQueriesSizes.sm}px) {
            display: flex;
            justify-content: end;
        }
    }

    .custom-button {
        padding: 8px 12px;
    }
`;

export const LangWrap = styled.div`
    margin-bottom: 12px;

    &.desktop {
        display: none;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            display: flex;
            margin-bottom: 0;
        }
    }

    display: flex;
    align-items: center;

    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 0.875em;
        line-height: 1;
        letter-spacing: 0.04em;
        font-family: centrano2-book;
        color: ${colors.primary};
    }

    img.flag {
        margin-right: 8px;
        height: 18px;
    }
`;



export const DownloadRowStyles = styled.div`
    border-bottom: 1px solid ${colors.UILightTertiary};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 36px 0;

    .text-wrap {
        display: flex;
        flex-direction: column;

        .products-wrap {
            display: flex;

            .Component-ProductLogo {
                margin-bottom: 16px;
                margin-right: 8px;

                img {
                    height: 22px;
                }
            }

        }


        .title {
            display: flex;
            flex-direction: row;
            align-items: center;


            h4 {
                font-style: normal;
                font-weight: 400;
                font-size: 0.875em;
                line-height: 1;
                letter-spacing: 0.04em;
                font-family: centrano2-book;
                color: ${colors.primary};
            }

            img.flag {
                margin-right: 8px;
                height: 18px;
            }
        }
    }

`;