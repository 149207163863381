import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundHalfGreyReverse, BackgroundBlue } from '../GlobalStyles';
import { LogoGridV2 } from '../components/V2/LogoGrid-V2/LogoGridV2';
import { TextBlock } from '../components/V2/TextBlock/TextBlock';
import { HighlightBlockRightImage } from '../components/V2/HighlightBlocks/HighlightBlockRightImage/HighlightBlockRightImage';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';

import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { Grid } from '../components/V2/Grid/Grid';
import { SplitColumnExtraText } from '../components/V2/SplitColumnExtraText/SplitColumn';
import { Accordion } from '../components/V2/Accordion/Accordion';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';

interface Props {
  Lang?: string;
}

const AerospaceTemplate = ({ Lang = 'en', ...props }: Props) => {
  const data = require(`../../data/content/Pages/aerospace/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const vertical = 'aerospace';

  return (
    <>
      <HelmetContainer currentPage={'aerospace'} lang={Lang} title={data.helmet.title} />

      <PageContainer lang={Lang}>
        <BackgroundGrey>
          <Header demoVertical={vertical} lang={Lang} />
          <LargeHero
            AddSalesTel={true}
            BackgroundStyle={'cover'}
            Copy={data.content.Hero.copy}
            Country={Lang}
            Background={'/assets/images/pages/aerospace/hero-aerospace-v2.webp'}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true,
              theme: 'primary'
            }}
          />
          <AnnoucementBar
            AdditionalClass={'offset-bottom increase-z header-margin-0'}
            Image={'https://oqtonadmin.com/wp-content/uploads/2024/03/aerospace-annoucement.png'}
            Content={
              '<h3>From Physical Part to Parametric Model: 3D Scanning Applications in Aerospace</h3><p>Download this whitepaper and discover how companies across the Aerospace industry are leveraging 3D Scanning to design and manufacture better parts, faster</p>'
            }
            Link={{
              label: 'Download whitepaper',
              target: '_blank',
              link: 'https://oqton.com/news/ebooks/3d-scanning-applications-in-aerospace/'
            }}
          />
          <HighlightBlockRightImage
            AdditionalClass={'decreased-padding announcement-bar-on-top'}
            Theme={'dark-teal'}
            ImageType={'Background'}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/pages/aerospace/right-background-aerospace.svg'
            }}
            Title={data.content.HighlightBlockRightImage.title}
            Copy={data.content.HighlightBlockRightImage.copy}
          />
          {data.content.split_blocks.map((block, index) => {
            let orientation = 'Right';
            if (index % 2) {
              orientation = 'Left';
            }

            return (
              <SplitColumnExtraText
                key={index}
                ImagePosition={orientation}
                Button={block.button}
                Copy={`<h3>${block.micro}</h3><h2>${block.title}</h2><h4>${block.subtitle}</h4><p>${block.copy}</p>`}
                Image={{
                  src: block.imageURL,
                  alt: ''
                }}
              />
            );
          })}{' '}
        </BackgroundGrey>

        <LogoGridV2
          Subtitle={data.content.Customers.subtitle}
          Title={data.content.Customers.title}
          AdditionalClasses={'left-headers'}
          TitleSize="l"
          SubTitleSize="m"
          Logos={[
            {
              src: '/assets/images/pages/aerospace/logos/logo-eos.png',
              alt: 'EOS'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-reinishaw.png',
              alt: 'Reinshaw'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-3dsystems.png',
              alt: '3D Systems'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-xactmetal.png',
              alt: 'Xact Metal'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-trumpf.png',
              alt: 'Trumpf'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-hp.png',
              alt: 'HP'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-stratasys.png',
              alt: 'Stratasys'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-farzoon.png',
              alt: 'Farzoon'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-freemelt.png',
              alt: 'Freemelt'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-dye_mansion.png',
              alt: 'DyeMansion'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-slm.png',
              alt: 'SLM'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-dmg_mori.png',
              alt: 'DMG Mori'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-additive_industries.png',
              alt: 'Additive Industries'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-wematter.png',
              alt: 'WeMatter'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-gom.png',
              alt: 'GOM'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-evixscan.png',
              alt: 'eviXscan 3D'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-faro.png',
              alt: 'FARO'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-artec.png',
              alt: 'Artec 3D'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-kreon.png',
              alt: 'Kreon'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-hexagon.png',
              alt: 'Hexagon'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-keyence.png',
              alt: 'Keyence'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-creaform.png',
              alt: 'Creaform'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-smartech.png',
              alt: 'Smarttext 3D'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-shinning3d.png',
              alt: 'Shinning 3D'
            },
            {
              src: '/assets/images/pages/aerospace/logos/logo-scantech.png',
              alt: 'Scantech'
            }
          ]}
        />

        <HighlightBlock
          Theme={'dark-teal'}
          Title={data.content.HighlightBlock2.title}
          Copy={data.content.HighlightBlock2.copy}
          Button={{
            copy: globalCopy.Buttons.RequestDemo,
            ModelVertical: vertical,
            ModelTitle: globalCopy.Buttons.RequestDemo,
            OpenDemoModel: true
          }}
          Size={'extraText'}
          Image={{
            title: '',
            alt: '',
            url: '/assets/images/pages/aerospace/jet.png'
          }}
        />
        <br />
        <br />
        <br />
        <BackgroundGrey>
          <ArticleBlock
            Title={'Aerospace Industry Insights'}
            Layout={'layout-1'}
            MostRecentBlogs={true}
            Topic={'aerospace'}
            Link={{
              copy: 'All Resources',
              link: '/news/'
            }}
          />
        </BackgroundGrey>
        <Accordion Title={data.content.Accordion.title} Items={data.content.Accordion.Items} />
      </PageContainer>
      <Footer lang={Lang} />
    </>
  );
};

export default AerospaceTemplate;
