import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import AllArticleData from '../../data/new-blog-data.json';
import BlogCategories from '../../data/blog-categories.json';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { Header } from '../components/layouts/HeaderComponent';
import GlobalStyles from '../GlobalStyles';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

import {
  NewsHeaderStyles,
  ResultWrap,
  CheckboxWrapper,
  FilterContainer,
  PaginationStyles,
  DownloadsGrid
} from './../styles/newsStyles';
import { NoResultsDisplay } from './../styles/newsStyles';
import { DownloadRow } from '../components/V2024/SingleDownloadRow/SingleDownloadRow';
import { usePageContext } from '../context/PageContext';

interface Props {
  baseCategory?: object;
  lang?: string;
}

const DownloadsTemplate = ({ baseCategory = [], lang = 'en', ...props }: Props) => {
  const pageContext = usePageContext();

  const [articles, setArticles] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);
  const articlesPerPage = 12; // Number of articles to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState(baseCategory);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedLangs, setSelectedLangs] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [openFilters, setOpenFilters] = useState(false);

  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [uniqueLangs, setUniqueLangs] = useState([]);

  const Copy = require(`../../data/content/News/${lang}.json`);

  const graphQuery = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "downloads" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
    }
  `);

  const content = graphQuery.allFile.edges[0].node.childRawJson.content;
  const data = JSON.parse(content);

  useEffect(() => {
    const fetchData = () => {
      setTimeout(() => {
        setContentLoaded(true);
        setArticles(data);
      }, 100);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (articles && articles.length > 0) {
      const categories = Array.from(
        new Map(articles.flatMap(article => article.tags).map(category => [category.slug, category])).values()
      );

      setUniqueCategories(categories);

      const langs = Array.from(new Set(articles.map(article => article.lang)));

      setUniqueLangs(langs);

      setFilteredArticles(
        articles.filter(article => {
          let productPass = true;
          let langPass = true;
          let searchReturn = true;

          if (!selectedProducts.includes('all') && selectedProducts.length > 0) {
            const hasSelectedProduct = article.tags.some(product => selectedProducts.includes(product.slug));
            if (!hasSelectedProduct) {
              productPass = false;
            }
          } else {
            productPass = true;
          }

          if (!selectedLangs.includes('all') && selectedLangs.length > 0) {
            const hasSelectedLang = selectedLangs.includes(article.lang);
            if (!hasSelectedLang) {
              langPass = false;
            }
          } else {
            langPass = true;
          }

          // Filter by search text
          if (searchText.trim() !== '') {
            const articleTitle = article.title.toLowerCase();
            const searchQuery = searchText.toLowerCase();
            if (!articleTitle.includes(searchQuery)) {
              searchReturn = false;
            }
          }

          if (productPass && langPass && searchReturn) {
            return true;
          } else {
            return false;
          }
        })
      );
    }
  }, [articles, selectedCategories, selectedTopics, selectedLangs, selectedProducts, searchText]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    let category = searchParams.get('category');
    let topic = searchParams.get('topics');
    let lang = searchParams.get('langs');
    let product = searchParams.get('products');
    const page = searchParams.get('page');
    const search = searchParams.get('search');

    if (topic != null) {
      topic = topic.toLowerCase();
      const topics = topic ? topic.split(',') : [];
      setSelectedTopics(topics);
    }
    if (lang != null) {
      lang = lang.toLowerCase();
      const langs = lang ? lang.split(',') : [];
      setSelectedLangs(langs);
    }
    if (product != null) {
      product = product.toLowerCase();
      const products = product ? product.split(',') : [];
      setSelectedProducts(products);
    }
    if (category != null) {
      category = category.toLowerCase();
      const categories = category ? category.split(',') : [];
      setSelectedCategories(categories);
    }
    if (page != null) {
      handlePagination(page);
    }
    if (search != null) {
      setSearchText(search);
    }
  }, []);

  useEffect(() => {
    //Re-writes the page url when the page / search text / categories gets update
    const urlParams = new URLSearchParams();
    const categoryString = selectedCategories.join(',');
    const productString = selectedProducts.join(',');
    const topicString = selectedTopics.join(',');
    const langString = selectedLangs.join(',');
    urlParams.set('category', categoryString);
    urlParams.set('products', productString);
    urlParams.set('topics', topicString);
    urlParams.set('langs', langString);
    urlParams.set('page', currentPage);
    urlParams.set('search', searchText);
    window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`);
  }, [selectedCategories, selectedTopics, selectedLangs, selectedProducts, currentPage, searchText]);

  const getNativeLabelByLanguageLabel = languageCode => {
    if (languageCode === 'zh-hans') {
      languageCode = 'cn';
    }
    const language = pageContext.langs.find(lang => lang.languageCode === languageCode);
    return language ? language.nativeLabel : languageCode;
  };

  const handlePagination = pageNumber => {
    setCurrentPage(pageNumber);
    const targetElement = document.getElementById('news-wrap');
    if (targetElement) {
      gsap.to(window, {
        duration: 0.75,
        scrollTo: { y: targetElement.offsetTop, offsetY: 50 }
      });
    }
  };

  const handleProductChange = categorySlug => {
    if (categorySlug === 'all') {
      setSelectedProducts(['all']);
      setCurrentPage(1);
    } else {
      let updateProducts = [...selectedProducts];

      if (updateProducts.includes('all')) {
        updateProducts = updateProducts.filter(slug => slug !== 'all');
      }

      const isSelected = updateProducts.includes(categorySlug);

      if (isSelected) {
        updateProducts = updateProducts.filter(slug => slug !== categorySlug);
      } else {
        updateProducts.push(categorySlug);
      }

      setSelectedProducts(updateProducts);
      setCurrentPage(1);
    }
  };

  const handleLangChange = categorySlug => {
    if (categorySlug === 'all') {
      setSelectedLangs(['all']);
      setCurrentPage(1);
    } else {
      let updateLangs = [...selectedLangs];

      if (updateLangs.includes('all')) {
        updateLangs = updateLangs.filter(slug => slug !== 'all');
      }

      const isSelected = updateLangs.includes(categorySlug);

      if (isSelected) {
        updateLangs = updateLangs.filter(slug => slug !== categorySlug);
      } else {
        updateLangs.push(categorySlug);
      }

      setSelectedLangs(updateLangs);
      setCurrentPage(1);
    }
  };

  const handleSearchChange = event => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  const handleToggleFilterWrap = () => {
    setOpenFilters(!openFilters);
  };

  return (
    <>
      <HelmetContainer lang={lang} title="Downloads" />

      <PageContainer lang={lang}>
        <GlobalStyles />
        <Header lang={lang} />

        <div className={'container'}>
          <NewsHeaderStyles>
            <h1>Brochures / Flyers</h1>

            <div className={'row'}>
              <input type="text" value={searchText} onChange={handleSearchChange} placeholder={'Search'} />

              <button onClick={handleToggleFilterWrap}>
                <img src={'/assets/icons/filter.png'} alt={''} />
                {Copy.filter_btn}
              </button>
            </div>

            {openFilters && (
              <FilterContainer>
                <CheckboxWrapper className="filters-products">
                  <h3>{Copy.products}</h3>
                  <div className="check-wrap">
                    <label>
                      <input
                        type="checkbox"
                        value="all"
                        checked={selectedProducts[0] === 'all' || selectedProducts.length === 0}
                        onChange={() => handleProductChange('all')}
                      />
                      All Products
                    </label>

                    {uniqueCategories.map((category, index) => {
                      const matchedCategory = articles
                        .flatMap(article => article.tags)
                        .find(tag => tag.slug === category.slug);

                      if (!matchedCategory) {
                        return null; // Skip rendering if no match is found
                      }

                      const { slug, title, name } = matchedCategory;
                      if (
                        slug === 'geomagic-control-x' ||
                        slug === 'd2p' ||
                        slug === '3dxpert' ||
                        slug === 'freeform' ||
                        slug === 'geomagic-for-solidworks' ||
                        slug === 'geomagic-design-x-products' ||
                        slug === 'manufacturing-os-products' ||
                        slug === 'geomagic-wrap'
                      ) {
                        return (
                          <label key={slug || index}>
                            <input
                              type="checkbox"
                              value={slug}
                              checked={selectedProducts.includes(slug)}
                              onChange={() => handleProductChange(slug)}
                            />
                            <span>{title || name || 'Unnamed Category'}</span>
                          </label>
                        );
                      }
                    })}
                  </div>
                </CheckboxWrapper>
                <div className={'divider'}></div>
                <CheckboxWrapper className={'filters-langs'}>
                  <h3>Languages</h3>
                  <div className="check-wrap">
                    <label>
                      <input
                        type="checkbox"
                        value={'all'}
                        checked={selectedLangs[0] === 'all' || selectedLangs.length === 0}
                        onChange={() => handleLangChange('all')}
                      />
                      <span>All</span>
                    </label>

                    {uniqueLangs.map((lang, index) => {
                      return (
                        <label key={lang || index}>
                          <input
                            type="checkbox"
                            value={lang}
                            checked={selectedLangs.includes(lang)}
                            onChange={() => handleLangChange(lang)}
                          />
                          <span>{getNativeLabelByLanguageLabel(lang)}</span>
                        </label>
                      );
                    })}
                  </div>
                </CheckboxWrapper>
              </FilterContainer>
            )}
          </NewsHeaderStyles>

          <ResultWrap>
            {filteredArticles.length} {Copy.results}
          </ResultWrap>

          <DownloadsGrid id={'news-wrap'}>
            <div className={'header'}>Products</div>
            <div className={'header'}>Name</div>
            <div className={'header'}>Language</div>
            <div className={'header'}></div>

            {currentArticles.map((article, index) => (
              <DownloadRow item={article} />
            ))}

            {currentArticles.length === 0 && contentLoaded && (
              <NoResultsDisplay>
                <h2>{Copy.noresults}</h2>
              </NoResultsDisplay>
            )}
            {!contentLoaded && (
              <NoResultsDisplay>
                <h2>...{Copy.loading}...</h2>
              </NoResultsDisplay>
            )}
          </DownloadsGrid>

          <PaginationStyles>
            {Array.from({ length: totalPages }, (_, index) => {
              // Display the current page and the pages before and after it
              if (
                index === 0 || // First page
                index === currentPage - 1 || // Current page
                index === totalPages - 1 || // Last page
                (index >= currentPage - 2 && // Pages after the current page
                  index <= currentPage) ||
                (index <= currentPage && // Pages before the current page
                  index >= currentPage - 1)
              ) {
                return (
                  <button key={index} onClick={() => handlePagination(index + 1)} disabled={currentPage === index + 1}>
                    {index + 1}
                  </button>
                );
              } else if (
                (index === currentPage - 3 && currentPage > 3) || // Add "..." before pages if there is a gap
                (index === currentPage + 1 && currentPage < totalPages - 2) // Add "..." after pages if there is a gap
              ) {
                return <span key={index}>...</span>;
              }
            })}
          </PaginationStyles>
        </div>

        <Footer />
      </PageContainer>
    </>
  );
};
export default DownloadsTemplate;
