import React from 'react';

import { Icon } from '../../subComponents/Icon';
import { ContentContainer, Title, Sub, IntroText, IntroLink } from './CareersStyles';

export const CareersHeader = ({ content }) => {
  return (
    <ContentContainer>
      <Title>{content.title}</Title>
      <Sub>{content.subtitle}</Sub>
      <IntroText>{content.intro_text}</IntroText>
    </ContentContainer>
  );
};
