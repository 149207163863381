import React, { useContext, useEffect, useState } from 'react';

import BlogCategories from '../../data/blog-categories.json';
import { SocialShareButtons } from '../components/V2/News/SocialShareButtons/SocialShareButtons';
import { TagsWrap } from '../components/V2/News/TagsWrap/TagsWrap';

import {
  SingleNewsWrapper,
  SingleNewsMainContent,
  SingleNewsSideColumn,
  SingleNewsContent,
  NewsHRDivider
} from '../styles/newsStyles.jsx';

import GlobalStyle from '../GlobalStyles';

// * Components
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { NewsSubNavigation } from '../components/V2/News/NewsSubNavigation/NewsSubNavigation';
import { BlogData } from '../components/V2/News/BlogData/BlogData';
import { FeaturedPartner } from '../components/V2/News/FeaturedPartner/FeaturedPartner';
import { SidebarTopics } from '../components/V2/News/SidebarTopics/SidebarTopics';
import { NewsletterFormComponent } from '../components/V2/Form/NewsletterForm';
import { createMarkUp } from '../components/V2/helpers';
import { ModalContext } from '../context/ModalContext';
import PostInner from './postInner';

interface Props {
  Lang?: string;
  pageContext?: {
    post: any;
  };
}

const PostPage: React.FC<Props> = ({ Lang = 'en', pageContext }) => {
  const post = pageContext?.post;
  const originalPost = pageContext?.post;
  const [updatedContent, setUpdatedContent] = useState(false);

  let headerLang = 'en';
  if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
    headerLang = 'zh-cn';
  }

  return (
    <>
      <HelmetContainer
        lang={headerLang}
        title={originalPost.title}
        description={post.summary}
        image={originalPost.featuredImage}
      />

      <PageContainer lang={'en'}>
        <GlobalStyle />
        <Header lang={headerLang} />

        <NewsSubNavigation />

        <SingleNewsWrapper className={'container'}>
          <SingleNewsMainContent>
            <PostInner Lang={'en'} post={post} />

            <SocialShareButtons />
          </SingleNewsMainContent>
          <SingleNewsSideColumn>
            <SidebarTopics />
          </SingleNewsSideColumn>
        </SingleNewsWrapper>

        <div className={'spacing-xl'}></div>

        <div className={'container'}>
          <NewsletterFormComponent Categories={post.categories} />

          <NewsHRDivider />
          <TagsWrap Categories={post.categories} />
          {/*<RelatedPosts Articles={relatedArticles} />*/}
        </div>
        <Footer lang={headerLang} />
      </PageContainer>
    </>
  );
};

export default PostPage;
