import React from 'react';

import { Main } from '../styles/background';

import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { ThankYou } from '../components/layouts/ThankYou';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const ContactUsThankYou = () => {
  return (
    <>
      <HelmetContainer title="Thank you" />

      <PageContainer lang={'fr'}>
        <Main noPadding>
          <Content>
            <Header />
            <ThankYou
              customText={
                <>Merci d'avoir contacté notre équipe, nous répondrons à votre demande dans les plus bref délais.</>
              }
            />
          </Content>
        </Main>
        <Footer />
      </PageContainer>
    </>
  );
};

export default ContactUsThankYou;
