import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { createMarkUp } from '../helpers';

import {
  ComponentArticleBlock,
  LinkWrap,
  ArticleWrapLayout2,
  ArticleLayout2,
  ArticleLayout2Mini,
  ArticleWrapLayout3,
  ColumnWrap,
  Category,
  ArticleWrapLayout1,
  ArticleWrapLayout4
} from './styles.jsx';
import { SingleArticle } from './SingleArticle/SingleArticle';

interface Props {
  Layout?: 'layout-1' | 'layout-2' | 'layout-3' | 'layout-4';
  Title?: string;
  ID?: string;
  Copy?: string;
  titleClass?: string;
  Items?: object;
  Link?: object;
  Topic?: string;
  MostRecentBlogs?: boolean;
  Theme?: 'light' | 'dark';
}

export const ArticleBlock = ({
  ID = '',
  Title = '',
  Copy,
  Items = [],
  titleClass = '',
  Layout = 'layout-1',
  Theme = 'light',
  MostRecentBlogs = false,
  Link = null,
  Topic = null,
  ...props
}: Props) => {
  const showLink = Link => {
    if (Link != null) {
      return (
        <LinkWrap className={'theme-' + Theme}>
          <div className={'title-underline '}></div>
          <a href={Link.link}>{Link.copy}</a>
        </LinkWrap>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const getLayout = Layout => {
    if (Layout === 'layout-1') {
      return (
        <ArticleWrapLayout1>
          {Items.map((item, index) => {
            return <SingleArticle Theme={Theme} ItemData={item} key={index} />;
          })}
        </ArticleWrapLayout1>
      );
    } else if (Layout === 'layout-2') {
      return (
        <ArticleWrapLayout2>
          {Items.map((item, index) => {
            if (index === 0) {
              return (
                <ArticleLayout2 className={'theme-' + Theme + ' key-' + index} href={item.link} key={index}>
                  <div className={'bgImg'} style={{ backgroundImage: 'url(' + item.image + ')' }}></div>
                  <div className={'text-wrap'}>
                    <h4>{item.title}</h4>
                    <Category>
                      <span>{item.category}</span> {item.readTime}
                    </Category>
                  </div>
                </ArticleLayout2>
              );
            } else {
              return '';
            }
          })}

          <ColumnWrap>
            {Items.map((item, index) => {
              if (index !== 0) {
                return (
                  <ArticleLayout2Mini className={'theme-' + Theme + ' key-' + index} href={item.link} key={index}>
                    <div className={'bgImg'} style={{ backgroundImage: 'url(' + item.image + ')' }}></div>
                    <div className={'text-wrap'}>
                      <h4>{item.title}</h4>
                      <Category className={'category-wrap'}>
                        <span>{item.category}</span> {item.readTime}
                      </Category>
                    </div>
                  </ArticleLayout2Mini>
                );
              } else {
                return '';
              }
            })}
          </ColumnWrap>
        </ArticleWrapLayout2>
      );
    } else if (Layout === 'layout-3') {
      return (
        <ArticleWrapLayout3>
          {Items.map((item, index) => {
            if (index < 3) {
              return <SingleArticle Theme={Theme} ItemData={item} Key={index} />;
            } else {
              return null;
            }
          })}
        </ArticleWrapLayout3>
      );
    } else if (Layout === 'layout-4') {
      return (
        <>
          <ArticleWrapLayout4>
            {Items.map((item, index) => {
              return <SingleArticle Theme={Theme} ItemData={item} key={index} />;
            })}
          </ArticleWrapLayout4>
        </>
      );
    }
  };

  if (MostRecentBlogs === true) {
    let NewItems: object = [];

    let blogItems = [];

    if (typeof Topic !== 'undefined' && Topic !== null) {
      if (Topic === 'manufacturing-os') {
        blogItems = require(`../../../../data/blogs/products/manufacturing-os.json`);
      }
      if (Topic === '3dxpert') {
        blogItems = require(`../../../../data/blogs/products/3dxpert.json`);
      }
      if (Topic === '3dxpert-customer-story') {
        blogItems = require(`../../../../data/blogs/products/3dxpert-customer-stories.json`);
      }
      if (Topic === 'geomagic-designx-customer-stories') {
        blogItems = require(`../../../../data/blogs/products/gdx-customer-stories.json`);
      }
      if (Topic === 'freeform') {
        blogItems = require(`../../../../data/blogs/products/freeform.json`);
      }
      if (Topic === 'additive-manufacturing') {
        blogItems = require(`../../../../data/blogs/topics/additive-manufacturing.json`);
      }
      if (Topic === 'geomagic-wrap') {
        blogItems = require(`../../../../data/blogs/products/geomagic-wrap.json`);
      }
      if (Topic === 'medical') {
        blogItems = require(`../../../../data/blogs/topics/medical.json`);
      }
      if (Topic === 'automotive') {
        blogItems = require(`../../../../data/blogs/topics/automotive.json`);
      }
      if (Topic === 'energy') {
        blogItems = require(`../../../../data/blogs/topics/energy.json`);
      }
      if (Topic === 'dental') {
        blogItems = require(`../../../../data/blogs/topics/dental.json`);
      }
      if (Topic === 'aerospace') {
        blogItems = require(`../../../../data/blogs/topics/aerospace.json`);
      }
      if (Topic === 'civil') {
        blogItems = require(`../../../../data/blogs/topics/civil-engineering.json`);
      }
      blogItems.forEach((item, index) => {
        if (NewItems.length < 5) {
          NewItems.push(item);
        }
      });
    } else {
      blogItems = require(`../../../../data/recent_blog_posts.json`);
      blogItems.forEach((item, index) => {
        if (NewItems.length < 5) {
          NewItems.push(item);
        }
      });
    }

    Items = NewItems;
  }

  return (
    <ComponentArticleBlock id={ID} className={'Component-ArticleBlock theme-' + Theme} {...props}>
      <GlobalStyle />
      <div className={'container'}>
        <div className={'article-block-header'}>
          {Title && <h2 className={titleClass} dangerouslySetInnerHTML={createMarkUp(Title)}></h2>}
          {Copy && <p>{Copy}</p>}
          {showLink(Link)}
        </div>
        {getLayout(Layout)}
      </div>
    </ComponentArticleBlock>
  );
};
