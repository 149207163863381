import React from 'react';
import { Footer } from '../../components/layouts/Footer';
import { Hero } from '../../components/layouts/Hero';
import { MediaComponent } from '../../components/layouts/MediaComponent';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { RDBold } from '../../styles/typography';
import { Features } from '../../components/layouts/FeatureContainer';
import { ListContainer } from '../../components/pageComponents/Styles/style';

import { HeaderSubtitle } from '../../components/subComponents/HeaderSubtitle';
import { HeaderBackground } from '../../components/pageComponents/header';
import { Header } from '../../components/layouts/HeaderComponent';
import { PageContainer } from '../../components/containers/PageContainer';

const GeomagicDesignXFreeTrial = () => {
  return (
    <>
      <HelmetContainer
        currentPage={'geomagic-controlx-free-trial'}
        image="/assets/images/pages/geomagic-control-x/hero.png"
        title="3D Inspection and metrology software"
        pageDescription="3D Inspection and Metrology Software | Developed by Oqton"
        description="3D Inspection and Metrology Software | Developed by Oqton"
        noindex
      />
      <PageContainer lang={'en'}>
        <HeaderBackground>
          <Header hideNavigation />
          <Hero
            title="<b>Trial for free </b> now"
            subtitle={<HeaderSubtitle icon="ControlX" boldText="Geomagic" text="Control X" />}
            image={{ src: '/assets/images/pages/geomagic-control-x/hero.png', alt: 'Geomagic Control X Hero' }}
            description="Request a free trial of Geomagic Control X. See how our industry-leading 3D metrology software makes it easier than ever to capture and interpret scan data, and output professional inspection reports."
            buttons={[{ text: 'Download Now', link: '/geomagic-controlx/download' }]}
            vertical="controlX"
          />
        </HeaderBackground>
        <MediaComponent
          decreasedPadding
          articles={[
            {
              title: '<b>Start scanning your parts</b>, with the Geomagic Control X free trial',
              content: (
                <React.Fragment>
                  <p>
                    Get access to the complete 3D scanning and metrology capabilities of Geomagic Control X with a
                    14-day free trial.
                  </p>
                  <br />

                  <ListContainer>
                    <li>
                      <RDBold>Easy to learn</RDBold>. Get your results with little or no training.
                    </li>
                    <li>
                      <RDBold>Handle huge data sets</RDBold> quickly and easily with scan-native algorithms built on a
                      CAD kernel.
                    </li>
                    <li>
                      Get all the tools you need for <RDBold>professional-level inspection</RDBold>.
                    </li>
                    <li>
                      <RDBold>Compatible with any 3D scanner</RDBold> or portable measuring arm.
                    </li>
                    <li>
                      Scan your parts to <RDBold>capture more information and leverage rich data</RDBold> to understand
                      why parts are in or out of tolerance.
                    </li>
                    <li>
                      <RDBold>Optimize your manufacturing processes</RDBold> to recognize and address issues faster.
                    </li>
                  </ListContainer>
                </React.Fragment>
              ),
              image: {
                src: '/assets/images/pages/geomagic-control-x/media_4.png',
                alt: 'Placeholder',
                imagePos: 'left'
              }
            }
          ]}
        />
        <Features
          title="Geomagic Control X Free Trial FAQs"
          columns="1"
          featureCards={[
            {
              heading: 'How do I download and install my Geomagic Control X free trial?',
              description:
                '<span>Request a free trial and fill in your contact details. A member of our team will then contact you with the installer and install information. The Geomagic Control X <a href="https://softwaresupport.oqton.com/s/article/Getting-Started-DXCX-Installation?language=en_US&r=403&ui-knowledge-components-aura-actions.KnowledgeArticleVersionCreateDraftFromOnlineAction.createDraftFromOnlineArticle=1">step-by-step installation</a> guide can also be found on our support site.</span>'
            },
            {
              heading: 'How long does my free trial of Geomagic Control X last?',
              description:
                'Once you have received your installer download link, your free trial will last 14 days from the day of activation.'
            },
            {
              heading: 'What are the minimum system requirements to run Geomagic Control X?',
              description:
                '<ul><li>Windows 8.1 (64-bit)</li><li>Intel or AMDS processor with a minimum 2.0 GHz</li><li>8GB RAM</li><li>30GB of available hard-disc space (additional space may be required for cache)</li><li>32-bit true colour display, screen resolution 1280x960</li><li>GPU - OpenGL 3.0 or above enabled graphics card with 4GB of RAM or more</li><li>See <a href="https://softwaresupport.oqton.com/s/article/Geomagic-Control-X?language=en_US">full system requirements</a> here.</li></ul>'
            },
            {
              heading: 'Where can I get technical support during my trial of Geomagic Control X?',
              description:
                '<span>A comprehensive list of FAQs can be found on our <a href="https://softwaresupport.oqton.com/s/article/Control-X-Knowledge-Base?language=en_US">Geomagic Control X Knowledge Base</a>. If you can’t find what you are looking for, you can submit a case to our support team</span>'
            },
            {
              heading: 'Where can I learn the basics to help me with my Geomagic Control X free trial?',
              description:
                '<span>We have put together a series of <a href="https://softwaresupport.oqton.com/s/article/Control-X-Introductory-Tutorial-Start-Page?language=en_US&r=286&ui-knowledge-components-aura-actions.KnowledgeArticleVersionCreateDraftFromOnlineAction.createDraftFromOnlineArticle=1">Geomagic Control X tutorial videos</a>to get you up and running during your free trial.</span>'
            },
            {
              heading: 'What languages are supported in the free trial of Geomagic Control X?',
              description:
                'The Geomagic Control X free trial supports the following languages:\n' +
                'English, Chinese, Japanese, Korean, German, Italian, Russian, Portuguese, Spanish, Polish, Turkish, French and Czech.'
            },
            {
              heading: 'How do I cancel my Geomagic Control X free trial?',
              description: 'Your Geomagic Control X free trial will automatically end after the 14 day period.'
            }
          ]}
        />
        <Footer />
      </PageContainer>
    </>
  );
};

export default GeomagicDesignXFreeTrial;
