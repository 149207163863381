import React from 'react';

import { Icon } from '../../subComponents/Icon';
import { createMarkUp } from '../../V2/helpers';
import {
  CareersOpenContainer,
  OpenContact,
  OpenTitle,
  OpenText,
  OpenSub,
  OpenPositions,
  OpenSection,
  OpenSectionTitle,
  OpenSectionLink
} from './CareersStyles';

export const CareersOpen = ({ content }) => {
  return (
    <CareersOpenContainer>
      <OpenTitle dangerouslySetInnerHTML={createMarkUp(content.title)}></OpenTitle>
      <OpenSub>{content.micro_title}</OpenSub>
      <OpenText>{content.text}</OpenText>
      <OpenText>{content.copy}</OpenText>
      <OpenSection>
        <OpenSectionTitle>{content.email_prompt}</OpenSectionTitle>
        <OpenSectionLink href="mailto:careers@oqton.com?subject=Job application">
          {content.email_us_button} <Icon name="Arrow" color="accent" size={23} ml={10} />
        </OpenSectionLink>
      </OpenSection>
    </CareersOpenContainer>
  );
};
