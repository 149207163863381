import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundHalfGreyReverse, BackgroundBlue } from '../GlobalStyles';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';

import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { Grid } from '../components/V2/Grid/Grid';
import { Accordion } from '../components/V2/Accordion/Accordion';
import { IconGrid } from '../components/V2/IconGrid/IconGrid';
import { SolutionBlock } from '../components/V2/SolutionBlock/SolutionBlock';

interface Props {
  Lang?: string;
  globalData?: object;
  pageContent?: object;
}

const CivilTemplate = ({ Lang = 'en', globalData, pageContent, ...props }: Props) => {
  const data = pageContent;

  const vertical = 'civil';

  return (
    <>
      <HelmetContainer
        currentPage={'civil'}
        lang={Lang}
        title={data.helmet.title}
        description={data.helmet.description}
        pageDescription={data.helmet.description}
        image={'/assets/2024/pages/civil/civil-share.png'}
      />

      <PageContainer lang={Lang}>
        <BackgroundGrey>
          <Header lang={Lang} demoVertical={vertical} demoTitle={data.content.hero.title} />
          <LargeHero
            AdditionalClasses={'civil-hero'}
            BackgroundStyle={'cover'}
            Background={'/assets/2024/pages/civil/CIvilHeader.png'}
            Copy={`<h1><b>${data.content.hero.title}</b></h1><p>${data.content.hero.copy}</p>`}
            Button={{
              ModelTitle: globalData.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalData.Buttons.RequestDemo,
              OpenDemoModel: true,
              theme: 'primary'
            }}
            AddSalesTel={true}
          />

          <IconGrid
            Theme={'dark'}
            Title={data.content.why.title}
            Copy={data.content.why.copy}
            Items={data.content.why.items}
          />

          <Grid
            additionalClasses={'flexible '}
            Copy={data.content.keyApplications.Copy}
            Title={data.content.keyApplications.Title}
            Items={data.content.keyApplications.Items}
          />
        </BackgroundGrey>

        <BackgroundHalfGreyReverse>
          <HighlightBlock
            MicroTitle={data.content.highlight.micro}
            Title={`<b>${data.content.highlight.title}</b>`}
            ImageType={'Background'}
            Image={{
              url: '/assets/2024/pages/civil/CTA-CivilImage.png'
            }}
            Theme={'PrimaryGradient'}
            Button={{
              ModelTitle: globalData.Buttons.RequestDemo,
              ModelVertical: vertical,
              OpenDemoModel: true,
              copy: globalData.Buttons.RequestDemo,
              Theme: 'accent-teal'
            }}
          />
        </BackgroundHalfGreyReverse>

        <ArticleBlock
          Layout={'layout-4'}
          MostRecentBlogs={true}
          Topic={'civil'}
          Title={data.content.articleTitle}
          Link={{
            link: '/news/',
            copy: globalData.Buttons.Insights
          }}
        />

        <SolutionBlock
          Title={data.content.solutions.Title}
          Theme={'dark'}
          Items={[
            {
              logo: 'geomagic-designx',
              title: data.content.solutions.Items[0]['title'],
              link: globalData.Navigation.ProductURLs.geoDesignX,
              link_copy: globalData.Buttons.Learn
            },
            {
              logo: 'geomagic-controlx',
              title: data.content.solutions.Items[1]['title'],
              link: globalData.Navigation.ProductURLs.geoControlX,
              link_copy: globalData.Buttons.Learn
            },
            {
              logo: 'geomagic-wrap',
              title: data.content.solutions.Items[2]['title'],
              link: globalData.Navigation.ProductURLs.geoWrap,
              link_copy: globalData.Buttons.Learn
            }
          ]}
        />

        <Accordion {...data.content.accordion} />
      </PageContainer>
      <Footer lang={Lang} Lang={Lang} />
    </>
  );
};

export default CivilTemplate;
