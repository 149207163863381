import React, { useEffect, useState } from 'react';
import { ComponentStyles } from './styles.jsx';
import { ButtonComponent } from '../../V2/Button/Button';
import { graphql, useStaticQuery } from 'gatsby';

interface Props {
  detectedLanguage?: string;
  pageLanguage?: string;
  closeFunction?: any;
}

export const LanguagePrompt = ({ detectedLanguage, pageLanguage, closeFunction, ...props }: Props) => {
  const [localLink, setLocalLink] = useState(null);
  const [pageGlobalKey, setPageGlobalKey] = useState(null);

  const data = useStaticQuery(graphql`
    query {
      sitemap: allFile(filter: { name: { eq: "sitemap-data" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
      languages: allFile(filter: { name: { eq: "langs" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
      prompts: allFile(filter: { name: { eq: "language-prompts" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
    }
  `);

  const content = data.sitemap.edges[0].node.childRawJson.content;
  const sitemap = JSON.parse(content); // Parse the raw JSON

  const languages = data.languages.edges[0].node.childRawJson.content;
  const langJSON = JSON.parse(languages); // Parse the raw JSON

  let prompts = data.prompts.edges[0].node.childRawJson.content;
  prompts = JSON.parse(prompts); // Parse the raw JSON

  useEffect(() => {
    function normalizePath(path) {
      return path.replace(/\/+$/, '').trim(); // Remove trailing slashes and trim whitespace
    }

    function findPageKey(currentPath) {
      const normalizedCurrentPath = normalizePath(currentPath);

      for (const [key, paths] of Object.entries(sitemap)) {
        for (const [lang, path] of Object.entries(paths)) {
          if (normalizePath(path) === normalizedCurrentPath) {
            return key;
          }
        }
      }
      return null; // Return null if no match is found
    }

    if (window) {
      console.log(window.location.pathname);
      const pageKey = findPageKey(window.location.pathname);
      setPageGlobalKey(pageKey);

      if (sitemap[pageKey][detectedLanguage]) {
        setLocalLink(sitemap[pageKey][detectedLanguage]);
        setLocalLink(sitemap[pageKey][detectedLanguage]);
      }
    }
  }, []);

  if (localLink) {
    return (
      <ComponentStyles className={'Component-LanguagePrompt'} {...props}>
        <div className={'popup'}>
          <button className={'close'} onClick={closeFunction}>
            <img src={'/assets/icons/close.svg'} alt={''} />
          </button>

          <section className={'main'}>
            <h3>{prompts[detectedLanguage]['open']}</h3>
            <p>{prompts[detectedLanguage]['settings']}</p>
            <div className={'btn-wrap'}>
              <ButtonComponent Link={localLink} Label={prompts[detectedLanguage]['switch']} />
              <ButtonComponent
                Theme={'stripped'}
                Label={prompts[pageLanguage]['switch']}
                ClickFunction={closeFunction}
              />
            </div>
          </section>
          <section className={'prompt'}>
            <p>{prompts[detectedLanguage]['alternate']}</p>
            <div className={'grid'}>
              {langJSON.map((item, index) => {
                let link = sitemap[pageGlobalKey][item.languageCode];

                if (link === undefined && item.languageCode == 'cn') {
                  link = sitemap[pageGlobalKey]['zh-cn'];
                }

                if (!link) {
                  link = sitemap['index'][item.languageCode];
                }

                if (item.languageCode === 'cn') {
                  link = 'https://oqton.cn' + link;
                }

                if (link) {
                  return (
                    <a key={index} href={link}>
                      <img src={`/assets/icons/flags/rectangular/${item.languageCode}.png`} alt={item.nativeLabel} />
                      {item.nativeLabel}
                    </a>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </section>
        </div>
      </ComponentStyles>
    );
  } else {
    return null;
  }
};
