import React from 'react';

import { ComponentStyles, ItemContainer } from './styles.jsx';
import { FormComponent } from '../../V2/Form/Form';

interface Props {
  Title?: string;
  Lang?: string;
}

export const HeroForm = ({ Lang = 'en', Title = '', ...props }: Props) => {
  return (
    <ComponentStyles className={'Component-HeroForm'} {...props}>
      <h2>{Title}</h2>
      <hr className={'divider'} />
      <FormComponent
        Route={'request-a-trial'}
        Lang={Lang}
        GoToThankYouPage={false}
        SubmitButtonText={'Get Demo'}
        PrefilledFields={[{ field: 'product_interest', value: 'Freeform' }]}
        AdditionalClasses={'columns-2 in-page-form theme-freeform'}
        HiddenFields={['message', 'product_interest']}
      />
    </ComponentStyles>
  );
};
