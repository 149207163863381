import React from 'react';
import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { FreeTrial } from '../../components/V2/3DXpert-FreeTrial/3DXpert-FreeTrial';
import { Background3DX, BackgroundGrey } from '../../GlobalStyles';

const threedXpertXFreeTrial = () => {
  const vertical = 'NewForm-3dxpert';

  return (
    <PageContainer lang={'en'}>
      <Background3DX>
        <Header demoVertical={vertical} whiteStripped={true} />

        <HelmetContainer
          showChatlio={false}
          title="3DXpert Free Trial"
          pageDescription="Prepare, optimize, and 3D print high-quality parts in record time. See how you can streamline the design to print workflow with a free trial of 3DXpert. | Developed by Oqton"
          description="Prepare, optimize, and 3D print high-quality parts in record time. See how you can streamline the design to print workflow with a free trial of 3DXpert. | Developed by Oqton"
        />

        <FreeTrial />
      </Background3DX>

      <Footer />
    </PageContainer>
  );
};

export default threedXpertXFreeTrial;
