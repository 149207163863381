import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const ComponentStyles = styled.div`

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(35, 64, 87, 0.5);
    backdrop-filter: blur(5px);
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .popup {
        width: 700px;
        max-height: 90vh;
        max-width: calc(100% - 32px);
        background: rgba(248, 247, 246, 0.9);
        /* UI-Light/Borders Secondary */
        border: 1px solid rgba(35, 64, 87, 0.2);
        box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(5px);
        border-radius: 6px;
        overflow-y: scroll;
        overflow-x: hidden;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            overflow-y: hidden;
            overflow-x: hidden;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
            line-height: 1.3125;
            letter-spacing: 0.02em;
            color: ${colors.tertiary};
            font-family: centrano2-book;
        }

        section.prompt {

            background-image: url("/assets/2024/backgrounds/map-overlay.png");
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            
            padding: 32px;

            @media (min-width: ${mediaQueriesSizes.sm}px) {
                padding: 32px 50px 50px;
            }


            h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 1.25em;
                line-height: 1.3;
                letter-spacing: 0.02em;
                color: ${colors.primary};
                margin-bottom: 8px;
            }
        }

        .main {
            background: #FFFFFF;
            border-radius: 6px 6px 0px 0px;
            padding: 56px 32px 32px;
            @media (min-width: ${mediaQueriesSizes.sm}px) {
                padding: 50px 50px 32px;
            }

            h3 {
                font-weight: 700;
                font-size: 1.25em;
                line-height: 1.3;
                letter-spacing: 0.02em;
                color: ${colors.primary};
                margin-bottom: 12px;
            }

            p {
                font-family: centrano2-book;
                font-style: normal;
                font-weight: 400;
                font-size: 1em;
                line-height: 1.3;
                letter-spacing: 0.02em;
                color: ${colors.tertiary};
                text-wrap: auto;
            }

            .btn-wrap {
                @media (min-width: ${mediaQueriesSizes.sm}px) {
                    display: flex;
                    align-items: center;
                    align-content: center;
                }
                margin-top: 1.5em;
                .custom-button {
                    margin-top: 1.5em;
                    @media (min-width: ${mediaQueriesSizes.sm}px) {
                        margin-top: 0;
                        margin-right: 1em;
                        &.theme-stripped{
                            position: relative;
                            top: 4px;
                        }
                    }
                }
            }

        }

        .grid {
            margin-top: 32px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 24px;
            grid-row-gap: 24px;

            @media (min-width: ${mediaQueriesSizes.sm}px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width: ${mediaQueriesSizes.md}px) {
                grid-template-columns: repeat(4, 1fr);
            }

            a {
                text-decoration: none;
                font-style: normal;
                font-weight: 500;
                font-size: 0.9375em;
                line-height: 1;
                letter-spacing: 0.02em;
                color: ${colors.primary};
                display: flex;
                align-content: center;
                align-items: center;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    width: calc(100% + 16px);
                    height: calc(100% + 20px);
                    background: ${colors.UILightStackAccent};
                    position: absolute;
                    top: -10px;
                    left: -8px;
                    opacity: 0;
                    border-radius: 8px;
                    transition: 0.25s;
                    z-index: -1;
                }

                &:hover {
                    &:before {
                        opacity: 1;
                    }
                }

                img {
                    width: 28px;
                    margin-right: 4px;
                }
            }

        }

        button {
            position: absolute;
            width: 32px;
            height: 32px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            top: 24px;
            right: 24px;
            background: transparent;
            border: 6px;
            border-radius: 8px;
            cursor: pointer;

            img {
                margin: 0;
                margin-right: 0;
                position: relative;
            }

            &:hover {
                /* UI-Light/Stack Accent */
                background: ${colors.UILightStackSecondary}
            }

        }
    }
`;