import React, { useEffect, useState } from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { BackgroundGrey, BackgroundHalfGreyReverse } from '../GlobalStyles';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { ProductSlider } from '../components/V2/ProductSlider/ProductSlider';
import { SocialFollow } from '../components/V2/SocialFollow/SocialFollow';
import { ThankYouSliderWrap } from '../components/V2/GeneralStyles';

interface Props {
  Lang?: string;
  Product?: string;
  Location?: string;
}

const ThankYouPageTemplate = ({ Lang = 'en', Product = undefined, ...props }: Props) => {
  let location = props['Location'];
  let data = require(`../../data/content/Pages/thank-you/${Lang}.json`);
  let globalCopy = require(`../../data/content/Global/${Lang}.json`);

  const [name, setName] = useState('');
  const [lang, setLang] = useState('');
  const [prod, setProd] = useState('');

  //console.log(props['Location']);

  useEffect(() => {
    // Get the search string from window.location
    const searchParams = new URLSearchParams(window.location.search);
    // Get the values of the 'name' and 'lang' parameters
    const nameParam = searchParams.get('name');
    const langParam = searchParams.get('lang');

    // Update the component state with the parameter values
    setName(nameParam || '');
    setLang(langParam || '');

    const componentName = Product;
    const product = data.products.find(
      (product: { Component: string | undefined }) => product.Component === componentName
    );
    if (product) {
      setProd(product.title || '');
    }
  }, []);

  const Copy =
    location && location.includes('/download/')
      ? `<h2><b>${data.default_content.hero.download + ' ' + prod}</b></h2><hr/>`
      : `<h2><b>${data.default_content.hero.title} ${name}!</b></h2><hr/><p class="large">${data.default_content.hero.copy}</p>`;
  return (
    <>
      <HelmetContainer lang={Lang} title={data.helmet.title} noindex />
      <PageContainer lang={Lang}>
        <Header lang={Lang} />

        <LargeHero
          AdditionalClasses={`theme-${Product}`}
          Logo={Product}
          Copy={Copy}
          BackgroundStyle={'image'}
          Background={'/assets/images/thankyou/thankyou.png'}
        />

        <ThankYouSliderWrap className={`theme-${Product}`}>
          <div className={'spacing-xl'}></div>
          <ProductSlider Lang={Lang} />
          <div className={'spacing-xl'}></div>
        </ThankYouSliderWrap>

        <SocialFollow Theme={`${Product}`} Title={`<p>${data.default_content.social}</p>`} />
      </PageContainer>
      <Footer lang={Lang} />
    </>
  );
};

export default ThankYouPageTemplate;
