import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import GeomagicDesignXPlansTemplate from '../../../templates/GDXPlansPage';

const GeomagicDesignXPlans = ({ pageContext }) => {
  const Lang = 'pt-br';

  const graphQuery = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "gdx-plans-pt-br" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
    }
  `);

  const content = graphQuery.allFile.edges[0].node.childRawJson.content;
  const data = JSON.parse(content);

  const globalCopy = pageContext.globalData;
  const sitemap = pageContext.sitemap;

  return <GeomagicDesignXPlansTemplate data={data} globalCopy={globalCopy} sitemap={sitemap} Lang={Lang} />;
};

export default GeomagicDesignXPlans;
