import React from 'react';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { ResourceLinks } from '../components/V2/ResourceLinks/ResourceLinks';
import { SplitColumn } from '../components/V2/SplitColumn/SplitColumn';
import { TextColumns } from '../components/V2/TextColumns/TextColumns';
import { BackgroundWave } from '../GlobalStyles';
import { LogoGrid } from '../components/V2/LogoGrid/LogoGrid';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';
import moment from 'moment/moment';

const ThreeDInspectionSolution = () => {
  const AnnoucementEndDate = moment('2023-12-12 17:00:00');
  const shouldDisplayAnnouncement = moment().isBefore(AnnoucementEndDate);
  const vertical = '3d-reverse-engineering';

  let additionalClasses = '';
  if (shouldDisplayAnnouncement) {
    additionalClasses = 'additional-padding-top';
  }

  return (
    <>
      <HelmetContainer
        currentPage={'3d-reverse-engineering'}
        title="3D Scanning Reverse Engineering Software | Oqton"
        description={`Maximize your 3D reverse engineering workflows with scan to CAD tools from Geomagic, developed by Oqton. `}
      />

      <PageContainer lang={'en'}>
        <BackgroundWave>
          <Header demoVertical={vertical} lang={'en'} />
          <LargeHero
            AddSalesTel={true}
            BackgroundStyle={'image'}
            Copy={
              '<h1><b>3D Reverse Engineering Software</b></h1><p>3D Scanning and Reverse Engineering workflows for any application. Take your scan to CAD faster.</p>'
            }
            Background={'/assets/images/solutions/3d-reverse-engineering-key-visual.webp'}
            Button={{
              ModelVertical: vertical,

              copy: 'Contact Us',
              OpenDemoModel: true,
              theme: 'accent-red'
            }}
          />

          <div>
            <AnnoucementBar
              Image={'https://oqtonadmin.com/wp-content/uploads/2024/10/Web-Civil.png'}
              AdditionalClass={'offset-bottom'}
              Link={{
                label: 'Download whitepaper',
                link: 'https://oqton.com/news/ebooks/3d-scanning-for-civil-infrastructure-applications/',
                target: '_blank'
              }}
              Content={
                '<h3>3D Scanning for Civil & Infrastructure Applications</h3><p>Learn how companies in the civil industry are acquiring accurate information about sites and structures through the use of 3D scanning technology.</p>'
              }
            />
          </div>
        </BackgroundWave>

        <TextColumns
          AdditionalClasses={'annoucement-top'}
          Theme={'dark'}
          Columns={'1'}
          Copy={
            "<p>If you're going to invest in a powerful 3D scanner, then you should be investing in an equally powerful scan to CAD software to maximize its potential. Prior to the digital CAD boom, 3D reverse engineering was carried out using a variety of measurement techniques, whether tape measures, callipers, or even a piece of string!<br/><br/>As technology has improved, so has our ability to measure with improved accuracy. The acceleration of <a href='/3d-scanning-solutions/'>3D scanning</a> and reverse engineering has catapulted 3D data capture, making it much more efficient, accurate and repeatable.<br/><br/>Combine 3D scanning and reverse engineering to digitize, redesign and rebuild your parts. Regardless of your needs, we have the software to fit. Read on to get a snapshot of what we offer.</p>"
          }
          Title={`Optimize your scan to CAD workflows`}
          Button={{
            copy: 'Which Reverse Engineering solution is right for me?',
            theme: 'white-outline',
            link: '/posts/which-reverse-engineering-solution-is-right-for-me/'
          }}
        />
        <LogoGrid
          Title={'Trusted by industry partners'}
          Copy={'Click each logo to learn more'}
          Logos={[
            {
              src: '/assets/images/partners/partner-logos/faro.png',
              alt: 'Faro',
              link: '/partners/faro/'
            },
            {
              src: '/assets/images/partners/partner-logos/evatronix.png',
              alt: 'Evatronix',
              link: '/partners/evatronix/'
            },
            {
              src: '/assets/images/partners/partner-logos/creaform.png',
              alt: 'Creaform',
              link: '/partners/creaform/'
            },
            {
              src: '/assets/images/partners/partner-logos/shining3d.png',
              alt: 'Shining3d',
              link: '/partners/shining-3d/'
            },
            {
              src: '/assets/images/partners/partner-logos/artec3d.png',
              alt: 'artec3d',
              link: '/partners/artec-3d/'
            },
            {
              src: 'https://i0.wp.com/oqtonadmin.com/wp-content/uploads/2023/10/Scantech-1.png',
              alt: 'scantech',
              link: '/partners/scantech/'
            },
            {
              src: '/assets/images/partners/partner-logos/kreon.png',
              alt: 'kreon',
              link: '/partners/kreon/'
            },
            {
              src: '/assets/images/partners/partner-logos/nikon.png',
              alt: 'nikon',
              link: '/partners/nikon/'
            },
            {
              src: '/assets/images/partners/partner-logos/hexagon.png',
              alt: 'hexagon',
              link: '/partners/hexagon/'
            },
            {
              src: '/assets/images/partners/partner-logos/smartech3d.png',
              alt: 'hexagon',
              link: '/partners/smarttech3d/'
            },
            {
              src: '/assets/images/partners/partner-logos/3d-maker-pro.png',
              alt: '3D Maker Pro'
            }
          ]}
        />

        <SplitColumn
          ImagePosition={'Left'}
          Logo={'geomagic-designx'}
          Copy={
            "<p><a href='/geomagic-designx'>Geomagic Design X</a> creates accurate CAD models from 3D scan data, faster, and more reliably than anything else on the market. Extract new business value from existing parts with Design X to accelerate your process sustainability.</p><p><a href='/geomagic-designx/plans/'>Choose a plan</a> to suit your budget and meet your reverse engineering needs with any 3D scanner. Starting at only $1,900, Geomagic Design X is the industry leader for reverse engineering software.</p>"
          }
          Image={{
            src: '/assets/images/solutions/3d-reverse-engineering-geomagic-design-x.webp',
            alt: ''
          }}
          Button={{
            copy: 'Learn more about Geomagic Design X',
            theme: 'white-outline',
            link: '/geomagic-designx'
          }}
        />
        <SplitColumn
          ImagePosition={'Right'}
          ImageOverlap={false}
          ImageBackground={false}
          Logo={'geomagic-wrap'}
          Copy={
            "<p><a href='/geomagic-wrap'>Geomagic Wrap</a> delivers the most affordable, fast, and accurate path from point cloud to 3D models. Ideal for creating perfect, watertight 3D models from complex or freeform scan data.</p>"
          }
          Background={'White'}
          Image={{
            src: '/assets/images/solutions/3d-reverse-engineering-geomagic-wrap.webp',
            alt: ''
          }}
          Button={{
            copy: 'Learn more about Geomagic Wrap',
            theme: 'white-outline',
            link: '/geomagic-wrap'
          }}
        />
        <SplitColumn
          ImagePosition={'Left'}
          ImageOverlap={false}
          ImageBackground={false}
          Logo={'geomagic-for-solid'}
          Copy={
            "<p><a href='/geomagic-for-solidworks/'>Geomagic for SOLIDWORKS</a> enables you to import data or scan directly into SOLIDWORKS. It's ideal for SOLIDWORKS users looking for a straightforward path from point cloud to complete SOLIDWORKS design file. Oqton are a proud SOLIDWORKS Gold Software partner.</p>"
          }
          Background={'Off-White'}
          Image={{
            src: '/assets/images/solutions/3d-reverse-engineering-geomagic-for-solidworks.webp',
            alt: ''
          }}
          Button={{
            copy: 'Learn more about Geomagic for SOLIDWORKS',
            theme: 'white-outline',
            link: '/geomagic-for-solidworks/'
          }}
        />

        <ResourceLinks
          Title={'Resources'}
          Copy={'Check out some of our great 3D Inspection content'}
          Button={{
            Label: 'Show All Resources',
            Link: '/news'
          }}
          Items={[
            {
              category: 'Blog',
              title: 'Design on the fly – 3 examples of 3D scanning accelerating product development\n',
              link: {
                target: '_blank',
                url: '/posts/design-on-the-fly-examples-of-3d-scanning-software-accelerating-product-development/',
                copy: 'Read blog'
              }
            },
            {
              category: 'Webinar',
              title: 'Improving Turbine Blade Manufacturing Efficiencies with Geomagic\n',
              link: {
                target: '_blank',
                url: 'https://oqton.com/news/webinars/turbine-blade-webinar/',
                copy: 'Watch Webinar'
              }
            },
            {
              category: 'Blog',
              title: 'A quick guide to 3D models in reverse engineering\n',
              link: {
                target: '_blank',
                url: '/posts/a-guide-to-3d-models-in-reverse-engineering/',
                copy: 'Read Blog'
              }
            }
          ]}
        />
        <Footer />
      </PageContainer>
    </>
  );
};

export default ThreeDInspectionSolution;
