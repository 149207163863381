import React from 'react';
import { usePageContext } from '../../../context/PageContext';

import { DownloadRowStyles, TitleWrapper, ProductsWrap, Divider, LangWrap, ButtonWrap, MobileWrap } from './styles.jsx';
import { ButtonComponent } from '../../V2/Button/Button';

interface Props {
  item: object;
}

const getLanguage = ({ lang }) => {
  return '';
};

export const DownloadRow = ({ item, ...props }: Props) => {
  const pageContext = usePageContext();

  const getNativeLabelByLanguageLabel = languageCode => {
    if (languageCode === 'zh-hans') {
      languageCode = 'cn';
    }
    const language = pageContext.langs.find(lang => lang.languageCode === languageCode);
    return language ? language.nativeLabel : languageCode;
  };

  const getProducts = ({ tags }) => {
    if (tags) {
      return tags.map(tag => {
        console.log(tag);
        if (
          tag.slug === 'geomagic-control-x' ||
          tag.slug === 'd2p' ||
          tag.slug === 'freeform' ||
          tag.slug === 'geomagic-for-solidworks' ||
          tag.slug === 'geomagic-wrap' ||
          tag.slug === '3dxpert' ||
          tag.slug === 'manufacturing-os-products' ||
          tag.slug === 'geomagic-design-x-products'
        ) {
          let url = '/assets/logos/' + tag.slug + '-icon-only.svg';

          return <img src={url} alt={''} />;
        }
      });
    }
  };

  return (
    <>
      <MobileWrap>
        <ProductsWrap className={'products-wrap mobile'}>{getProducts({ tags: item.tags })}</ProductsWrap>
        <TitleWrapper className={'title-wrap mobile'}>
          <h3>{item.title}</h3>
        </TitleWrapper>
        <LangWrap className={'lang-wrap mobile'}>
          <img className={'flag'} alt="" src={`/assets/icons/flags/circlular/${item.lang}.png`} />
          <h4>{getNativeLabelByLanguageLabel(item.lang)}</h4>
        </LangWrap>
        <ButtonWrap className={'mobile'}>
          <ButtonComponent
            Target={'_blank'}
            Label={'Download PDF'}
            Theme={'transparent-with-grey-outline'}
            Icon={'download'}
            IconPosition={'left'}
            Link={item.download_url}
          />
        </ButtonWrap>
      </MobileWrap>

      <ProductsWrap className={'products-wrap desktop'}>{getProducts({ tags: item.tags })}</ProductsWrap>

      <TitleWrapper className={'title-wrap desktop'}>
        <h3>{item.title}</h3>
      </TitleWrapper>

      <LangWrap className={'desktop'}>
        <img className={'flag'} alt="" src={`/assets/icons/flags/circlular/${item.lang}.png`} />
        <h4>{getNativeLabelByLanguageLabel(item.lang)}</h4>
      </LangWrap>

      <ButtonWrap className={'desktop'}>
        <ButtonComponent
          Target={'_blank'}
          Label={'Download PDF'}
          Theme={'transparent-with-grey-outline'}
          Icon={'download'}
          IconPosition={'left'}
          Link={item.download_url}
        />
      </ButtonWrap>

      <Divider></Divider>
    </>
  );
};

// <DownloadRowStyles className={`Component_DownloadRow`} {...props}>
//   <div className={'text-wrap'}>
//     <div className={'products-wrap'}>
//       {getProducts({ tags: item.tags })}
//     </div>
//     <div className={'title'}>
//     </div>
//   </div>
//   <ButtonComponent
//       Target={'_blank'}
//       Label={'Download PDF'}
//       Theme={'transparent-with-grey-outline'}
//       Icon={'download'}
//       IconPosition={'left'}
//       Link={item.download_url}
//   />
// </DownloadRowStyles>
