import React from 'react';

import DownloadTemplate from '../../../templates/DownloadTemplateGWrap';

const DownloadGeomagicDX = () => {
  const Lang = 'tr';

  return <DownloadTemplate Lang={Lang} />;
};

export default DownloadGeomagicDX;
